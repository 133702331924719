// const typography = {
//   fontFamily: "'Plus Jakarta Sans', sans-serif;",
//   h1: {
//     fontWeight: 600,
//     fontSize: '2.25rem',
//     lineHeight: '2.75rem',
//     fontFamily: "'Plus Jakarta Sans', sans-serif;",
//   },
//   h2: {
//     fontWeight: 600,
//     fontSize: '1.875rem',
//     lineHeight: '2.25rem',
//     fontFamily: "'Plus Jakarta Sans', sans-serif;",
//   },
//   h3: {
//     fontWeight: 600,
//     fontSize: '1.5rem',
//     lineHeight: '1.75rem',
//     fontFamily: "'Plus Jakarta Sans', sans-serif;",
//   },
//   h4: {
//     fontWeight: 600,
//     fontSize: '1.3125rem',
//     lineHeight: '1.6rem',
//   },
//   h5: {
//     fontWeight: 600,
//     fontSize: '1.125rem',
//     lineHeight: '1.6rem',
//   },
//   h6: {
//     fontWeight: 600,
//     fontSize: '1rem',
//     lineHeight: '1.2rem',
//   },
//   button: {
//     textTransform: 'capitalize',
//     fontWeight: 400,
//   },
//   body1: {
//     fontSize: '0.875rem',
//     fontWeight: 400,
//     lineHeight: '1.334rem',
//   },
//   body2: {
//     fontSize: '0.75rem',
//     letterSpacing: '0rem',
//     fontWeight: 400,
//     lineHeight: '1rem',
//   },
//   subtitle1: {
//     fontSize: '0.875rem',
//     fontWeight: 400,
//   },
//   subtitle2: {
//     fontSize: '0.875rem',
//     fontWeight: 400,
//   },
// };

// export default typography;

import React from 'react'

const Typography = () => {
  return (
    <div>Typography</div>
  )
}

export default Typography
